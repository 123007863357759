import {makeRelease} from '/shared/release'

export const userAgent = navigator.userAgent

export const isIos = /(iPod|iPhone|iPad)/i.test(userAgent) && /AppleWebKit/i.test(userAgent)
export const isAndroid = /(android)/i.test(userAgent)

declare const __ENV__: undefined | {
    BACKEND_URL?: string
    RAVEN_DSN?: string
    AMPLITUDE_KEY?: string
}

export const dsn = __ENV__?.RAVEN_DSN ?? import.meta.env.RAVEN_DSN ?? undefined //'https://1406a57afa594482aa9569f5b2d81c7c@o48617.ingest.sentry.io/104134'
export const server = __ENV__?.BACKEND_URL ?? import.meta.env.BACKEND_URL ?? 'https://dev.ppl.do/api/graphql'
export const amplitudeKey = __ENV__?.AMPLITUDE_KEY ?? undefined

export const dev = import.meta.env.DEV
export const version = import.meta.env.VERSION ?? ''
export const commit = import.meta.env.COMMIT ?? ''

export const buildDate = import.meta.env.BUILD_DATE ?? new Date(0).toISOString()

export const release = makeRelease(version, commit, buildDate)
